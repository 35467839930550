// frontend/src/localization/translations.ts

export const translations = {
    sr: {
        // Property types
        house: 'Kuća',
        apartment: 'Stan',
        office: 'Kancelarija',

        // Deal types
        sale: 'Prodaja',
        rent: 'Izdavanje',

        // Property statuses
        ready: 'Gotovo',
        new: 'Novo',
        shared: 'Zajedničko vlasništvo',

        // Form fields
        propertyType: 'Vrsta nekretnine',
        dealType: 'Vrsta transakcije',
        city: 'Grad',
        district: 'Opština',
        address: 'Adresa',
        floorNumber: 'Sprat',
        totalFloors: 'Ukupno spratova',
        livingArea: 'Stambena površina',
        rooms: 'Sobe',
        bedrooms: 'Spavaće sobe',
        bathrooms: 'Kupatila',
        plotSize: 'Površina placa',
        price: 'Cena',
        heatingType: 'Grejanje',
        registered: 'Uknjiženo',
        waterSupply: 'Vodovod',
        sewage: 'Kanalizacija',

        // Buttons and actions
        create: 'Napravi',
        edit: 'Izmeni',
        save: 'Sačuvaj',
        cancel: 'Otkaži',
        delete: 'Obriši',
        back: 'Nazad',
        search: 'Pretraga',
        filter: 'Filter',

        // Headers and sections
        basicInfo: 'Osnovne informacije',
        propertyDetails: 'Detalji nekretnine',
        location: 'Lokacija',
        features: 'Karakteristike',
        additionalInfo: 'Dodatne informacije',
        ownerInfo: 'Informacije o vlasniku',

        // Dashboard
        dashboard: 'Kontrolna tabla',
        properties: 'Nekretnine',
        addProperty: 'Dodaj nekretninu',
        editProperty: 'Izmeni nekretninu',
        exportToSheets: 'Izvoz u Excel',
        active: 'Aktivno',
        inactive: 'Neaktivno',
        // Auth related
        login: 'Prijava',
        logout: 'Odjava',

        // Navigation
        home: 'Početna',

        // Common actions
        apply: 'Primeni',
        reset: 'Resetuj',

        // Currency
        currency: '€',
        pricePerMonth: '€/mesečno',

        // Measurements
        squareMeters: 'm²',

        // Status messages
        loading: 'Učitavanje...',
        noResults: 'Nema rezultata',
        error: 'Greška',
        // File related
        files: 'Fajlovi',
        fileType: 'Tip fajla',
        image: 'Slika',
        video: 'Video',
        document: 'Dokument',
        publiclyVisible: 'Javno vidljivo',
        selectFile: 'Izaberite fajl',
        uploading: 'Otpremanje...',
        upload: 'Otpremi',
        uploadedFiles: 'Otpremljeni fajlovi',
        public: 'Javno',
        private: 'Privatno',
        finish: 'Završi',
        floor: 'Sprat',
        of: 'od',
        description: 'Opis',
        noPropertiesFound: 'Nisu pronađene nekretnine koje odgovaraju vašim kriterijumima'
    },
    en: {
        // Property types
        house: 'House',
        apartment: 'Apartment',
        office: 'Office',

        // Deal types
        sale: 'Sale',
        rent: 'Rent',

        // Property statuses
        ready: 'Ready',
        new: 'New',
        shared: 'Shared ownership',

        // Form fields
        propertyType: 'Property Type',
        dealType: 'Deal Type',
        city: 'City',
        district: 'District',
        address: 'Address',
        floorNumber: 'Floor',
        totalFloors: 'Total Floors',
        livingArea: 'Living Area',
        rooms: 'Rooms',
        bedrooms: 'Bedrooms',
        bathrooms: 'Bathrooms',
        plotSize: 'Plot Size',
        price: 'Price',
        heatingType: 'Heating Type',
        registered: 'Registered',
        waterSupply: 'Water Supply',
        sewage: 'Sewage',

        // Buttons and actions
        create: 'Create',
        edit: 'Edit',
        save: 'Save',
        cancel: 'Cancel',
        delete: 'Delete',
        back: 'Back',
        search: 'Search',
        filter: 'Filter',

        // Headers and sections
        basicInfo: 'Basic Information',
        propertyDetails: 'Property Details',
        location: 'Location',
        features: 'Features',
        additionalInfo: 'Additional Information',
        ownerInfo: 'Owner Information',

        // Dashboard
        dashboard: 'Dashboard',
        properties: 'Properties',
        addProperty: 'Add Property',
        editProperty: 'Edit Property',
        exportToSheets: 'Export to Sheets',
        active: 'Active',
        inactive: 'Inactive',
        // Auth related
        login: 'Login',
        logout: 'Logout',

        // Navigation
        home: 'Home',

        // Common actions
        apply: 'Apply',
        reset: 'Reset',

        // Currency
        currency: '€',
        pricePerMonth: '€/month',

        // Measurements
        squareMeters: 'm²',

        // Status messages
        loading: 'Loading...',
        noResults: 'No results found',
        error: 'Error',
        // File related
        files: 'Files',
        fileType: 'File Type',
        image: 'Image',
        video: 'Video',
        document: 'Document',
        publiclyVisible: 'Publicly Visible',
        selectFile: 'Select File',
        uploading: 'Uploading...',
        upload: 'Upload',
        uploadedFiles: 'Uploaded Files',
        public: 'Public',
        private: 'Private',
        finish: 'Finish',
        floor: 'Floor',
        of: 'of',
        description: 'Description',
        noPropertiesFound: 'No properties found matching your criteria'
    },
    ru: {
        // Property types
        house: 'Дом',
        apartment: 'Квартира',
        office: 'Офис',

        // Deal types
        sale: 'Продажа',
        rent: 'Аренда',

        // Property statuses
        ready: 'Готово',
        new: 'Новое',
        shared: 'Совместная собственность',

        // Form fields
        propertyType: 'Тип недвижимости',
        dealType: 'Тип сделки',
        city: 'Город',
        district: 'Район',
        address: 'Адрес',
        floorNumber: 'Этаж',
        totalFloors: 'Всего этажей',
        livingArea: 'Жилая площадь',
        rooms: 'Комнаты',
        bedrooms: 'Спальни',
        bathrooms: 'Санузлы',
        plotSize: 'Площадь участка',
        price: 'Цена',
        heatingType: 'Тип отопления',
        registered: 'Зарегистрировано',
        waterSupply: 'Водоснабжение',
        sewage: 'Канализация',

        // Buttons and actions
        create: 'Создать',
        edit: 'Изменить',
        save: 'Сохранить',
        cancel: 'Отмена',
        delete: 'Удалить',
        back: 'Назад',
        search: 'Поиск',
        filter: 'Фильтр',

        // Headers and sections
        basicInfo: 'Основная информация',
        propertyDetails: 'Детали недвижимости',
        location: 'Расположение',
        features: 'Характеристики',
        additionalInfo: 'Дополнительная информация',
        ownerInfo: 'Информация о владельце',

        // Dashboard
        dashboard: 'Панель управления',
        properties: 'Объекты',
        addProperty: 'Добавить объект',
        editProperty: 'Редактировать объект',
        exportToSheets: 'Экспорт в Excel',
        active: 'Активно',
        inactive: 'Неактивно',
        // Auth related
        login: 'Войти',
        logout: 'Выйти',

        // Navigation
        home: 'Главная',

        // Common actions
        apply: 'Применить',
        reset: 'Сбросить',

        // Currency
        currency: '€',
        pricePerMonth: '€/мес.',

        // Measurements
        squareMeters: 'м²',

        // Status messages
        loading: 'Загрузка...',
        noResults: 'Результаты не найдены',
        error: 'Ошибка',
        // File related
        files: 'Файлы',
        fileType: 'Тип файла',
        image: 'Изображение',
        video: 'Видео',
        document: 'Документ',
        publiclyVisible: 'Публично доступно',
        selectFile: 'Выберите файл',
        uploading: 'Загрузка...',
        upload: 'Загрузить',
        uploadedFiles: 'Загруженные файлы',
        public: 'Публичный',
        private: 'Приватный',
        finish: 'Завершить',
        floor: 'Этаж',
        of: 'из',
        description: 'Описание',
        noPropertiesFound: 'Не найдено объектов по вашим критериям'
    }
};

type TranslationKeys = keyof typeof translations.en;

export const useTranslation = (language: string) => {
    return (key: TranslationKeys) => {
        return (translations[language as keyof typeof translations]?.[key] || translations.en[key]) as string;
    };
};